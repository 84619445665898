<template>
    <LoginComponent />
</template>


<script>

import LoginComponent from "@/components/auth/LoginComponent.vue";

export default {
  name: "LoginView",
  components: {
    LoginComponent,
  },
};
</script>