import authService from "@/services/request/auth/authService";
import {mapActions} from "vuex";
import * as actionType from "@/store/modules/auth/types/actions";

const TOKEN_NAME = "app_access_token";

export default {
    methods: {
        ...mapActions("auth", {
            updateUserData: actionType.USER_UPDATE_DATA,
        }),
        setToken(token) {
            localStorage.setItem(TOKEN_NAME, token);
        },
        async openGoogleSignInModal() {
            const client = window.google.accounts.oauth2.initTokenClient({
                client_id:
                  //"552205210981-dal2o9r2t8la39bv9t94hdtsul32rg0d.apps.googleusercontent.com",// DEV API KEY
                "177811106984-gtt2ccsks7caaoq15oto43719ffeafns.apps.googleusercontent.com",// PROD API KEY
                scope:
                    "https://www.googleapis.com/auth/userinfo.profile \
        https://www.googleapis.com/auth/userinfo.email",
                callback: "", // defined at request time
            });
            const tokenResponse = await new Promise((resolve, reject) => {
                try {
                    // Settle this promise in the response callback for requestAccessToken()
                    client.callback = (resp) => {
                        if (resp.error !== undefined) {
                            reject(resp);
                        }
                        resolve(resp);
                    };
                    client.requestAccessToken({ prompt: "consent" });
                } catch (err) {
                    console.log(err);
                }
            });
            let form = new FormData();
            form.append('provider_name', 'google');
            form.append('access_token', tokenResponse.access_token);
            const res = await authService.logInWithGoogle(form);

            this.setToken(res?.data?.data?.authorisation?.token);
            await this.updateUserData(res?.data?.data?.user);
            this.$router.push(this.$localize({ name: "panel" }));

        },
        async onSignInSuccess(response) {
            let form = new FormData();
            form.append('provider_name', 'facebook');
            form.append('access_token', response.authResponse.accessToken);
            await authService.signInWithSocial(form).then((res) => {
                if (res.status == 'Success') {
                    this.updateInfoLogged({
                        first_name: res.data.user_info.first_name,
                        last_name: res.data.user_info.last_name,
                        email: res.data.user_info.email,
                        role_name: res.data.user_info.role_name,
                        token: res.data.token,
                    });
                    sessionStorage.clear();
                    if (this.$vuetify.breakpoint.smAndDown) {
                        if (this.$route.query.mobileLogin) {
                            this.$router.push(`/passanger`);
                            localStorage.setItem("startDate", new Date());
                        } else {
                            this.$router.push('/')
                        }
                    } else {
                        this.$router.push('/')
                        if (this.$route.name == 'route_search_page') {
                            this.$router.push(`/passanger`);
                            localStorage.setItem("startDate", new Date());
                        }

                    }
                }
            })
        },
    }
}