<template>
  <AuthLayout>
    <v-card class="auth-card cust-index">
      <v-fade-transition mode="in-out">
        <v-col class="py-0 mb-8">
          <p
            :class="[
              'text-center',
              $vuetify.breakpoint.xs ? 'text-h6' : 'text-h4',
            ]"
            class="font-weight-bold mb-4"
          >
            {{ $t("auth.login_title") }}
          </p>
        </v-col>
      </v-fade-transition>
      <VForm @submit.prevent="onLogin">
        <VRow no-gutters>
          <v-col cols="12" class="py-0 mb-5">
            <VTextField
              :maxLength="50"
              :label="$t('form.email')"
              color="primary"
              dense
              height="48px"
              class="auth-input"
              outlined
              hide-details="auto"
              ref="login_email"
              v-model.trim="user.email"
              :error-messages="!$v.user.email.$pending && $v.user.email.$error ? [$t('form.errors.EmailRequired')] : []"
            />
          </v-col>
          <v-col cols="12" class="py-0 mb-5">
            <VTextField
              :maxLength="40"
              :label="$t('form.password')"
              :type="passwordVisible ? 'text' : 'password'"
              autocomplete="new-password"
              color="primary"
              dense
              @click:append="passwordVisible = !passwordVisible"
              :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              hide-details="auto"
              height="48px"
              class="auth-input"
              outlined
              ref="login_password"
              v-model.trim="user.password"
              :error-messages="!$v.user.password.$pending && $v.user.password.$error ? [$t('form.errors.PasswordRequired')] : []"
            />

          </v-col>
          <v-col cols="12" class="d-flex align-center justify-end py-0 mb-4">
            <v-btn
              color="primary"
              text
              class="text-transform-none"
              @click="showResetDialog()"
            >
              {{ $t("auth.btn_forgot") }}
            </v-btn>
          </v-col>
          <v-col class="text-center py-0 mb-6" cols="12">
            <v-btn
              block
              depressed
              large
              type="submit"
              class="btn-blue-bg text-transform-none"
              style="font-size: 16px; font-weight: 500"
            >
              {{ $t("auth.btn_login") }}
            </v-btn>
          </v-col>
          <v-col class="text-center py-0 mb-6 d-flex align-center" cols="12">
            <img
                class="mx-3"
                :src="require('@/assets/images/google.jpg')"
                alt=""
                style="width: 35px; height: 35px;"
            />
            <v-btn
              class="btn-blue-bg text-transform-none"
              style="font-size: 16px; font-weight: 500"
              @click="openGoogleSignInModal"
            >
              {{ $t("auth.btn_login_google") }}

            </v-btn>
          </v-col>
          <v-divider />
          <v-col
            class="text-center py-0 d-flex align-center justify-center"
            no-gutters
          >
            <v-btn
              color="primary"
              text
              @click="redirectToRegister"
              class="font-weight-medium text-transform-none px-2"
            >
              {{ $t("auth.btn_to_register") }}
            </v-btn>
          </v-col>
        </VRow>
      </VForm>
      <ForgotPasswordDialog
        :visible="isShowResetDialog"
        @close="isShowResetDialog = false"
      />
    </v-card>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/auth/authLayout/AuthLayout.vue";
import ForgotPasswordDialog from "./ForgotPasswordDialog.vue";
import notifications from "@/mixins/notifications";
import langMixin from "@/mixins/language";
import { validationMixin } from "vuelidate";
import * as actionTypes from "@/store/modules/auth/types/actions";
import { mapActions } from "vuex";
import authService from "@/services/request/auth/authService";
import authMixin from "@/mixins/authMixin";

import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "LoginComponent",
  components: {
    AuthLayout,
    ForgotPasswordDialog,
  },
  mixins: [validationMixin, notifications, langMixin, authMixin],
  data: () => ({
    passwordVisible: false,
    user: {
      email: "",
      password: "",
    },
    success: false,
    isShowResetDialog: false,
    welcomeShown: false,
  }),
  validations: {
    user: {
      email: { 
        required, 
        email 
      },
      password: { 
        required, 
        minLength: minLength(8) 
      },
    },
  },
  methods: {
    redirectToRegister() {
      this.$router.push({ name: "register" }).catch(() => {});
    },
    ...mapActions("auth", {
      logIn: actionTypes.LOG_IN,
    }),
    async onLogin() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const formData = new FormData();
          formData.append("email", this.user.email);
          formData.append("password", this.user.password);
          await this.logIn(formData);

          if (!this.welcomeShown) {
            this.setSuccessNotification(this.$t("auth.welcome_message"));
            this.welcomeShown = true; 
          }
          
          this.$router.push(this.$localize({ name: "news" }));
        } catch (e) {
          if(e.error.description) {
            this.setErrorNotification(this.$t("form.errors.loginError"));
          }
        }
      }
    },
    showResetDialog() {
      this.isShowResetDialog = true;
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
