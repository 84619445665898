<template>
  <v-dialog
    v-model="visibility"
    max-width="600"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    persistent
  >
    <v-card class="auth-card">
      <VCardTitle class="pa-0 mb-4 reset-pass-title">
        <p
          :class="$vuetify.breakpoint.xs ? 'text-h6' : 'text-h4'"
          class="font-weight-bold mb-2"
        >
          {{ $t("auth.reset_pass_title") }}
        </p>
        <p
          class="text-body-2 py-0 mb-0 reset-pass-desc"
          :style="!$vuetify.theme.dark ? 'color: #4B5262' : 'color: #fff'"
        >
          {{ $t("auth.reset_pass_desc") }}
        </p>
      </VCardTitle>
      <VCardText class="pb-0 px-0">
        <VContainer>
          <VRow>
            <VCol cols="12" class="px-0 mb-6">
              <VTextField
                outlined
                :label="$t('form.email')"
                v-model="email"
                dense
                height="48px"
                class="auth-input"
                hide-details="auto"
                color="primary"
                ref="login_email"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VRow>
        <VCol col="12" class="px-3">
          <VBtn
            class="btn-blue-bg text-transform-none"
            block
            large
            depressed
            :maxLength="50"
            :disabled="!email"
            @click="onSendEmail()"
            @keypress="validationForEmail($event)"
            @paste="validationForEmail($event)"
          >
            {{ $t("btn.confirm") }}
          </VBtn>
        </VCol>
      </VRow>
      <VRow @click="closeDialog()">
        <VCol cols="12" class="text-center">
          <VBtn text color="primary" class="text-transform-none">
            {{ $t("btn.back") }}
          </VBtn>
        </VCol>
      </VRow>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationForEmail } from "@/mixins/helpers";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  data: () => ({
    email: "",
  }),
  mixins: [dialogMixin],
  methods: {
    validationForEmail,
    closeDialog() {
      this.$emit("close");
      // this.$v.$reset();
    },
    onSendEmail() {
      console.log(this.email);
    },
  },
};
</script>

<style lang="scss">
.auth-card {
  max-width: 600px;
  width: 100%;
  padding: 35px 40px;
  border-radius: 10px;
  box-shadow: 0px 48px 100px 0px rgba(17, 12, 46, 0.15);
}

.auth-input {
  .v-input__control {
    .v-input__slot {
      .v-text-field__slot {
        .v-label {
          margin-top: 4px;
        }
      }
    }
  }
}

.reset-pass-title, .reset-pass-desc {
  word-break: break-word;
}
</style>
