<template>
  <div class="d-flex justify-center align-center content">
    <div class="content__logo-wrapper">
            <span>
              {{ 'GoDrop' }}
            </span>
    </div>
    <div class="content__auth-theme">
      <theme :isOutlined="!$vuetify.breakpoint.mobile" />
    </div>
    <div class="content__auth-language">
      <language :isOutlined="!$vuetify.breakpoint.mobile" />
    </div>
    <div class="d-flex align-center justify-content-space-between card responsive-display">
      <slot></slot>
      <img
          :src="require(`@/assets/layout_img/log-reg_img.svg`)"
          alt="Logo image"
          draggable="false"
          class="showimg"
      />
    </div>
  </div>
</template>

<script>
import Language from "@/components/layout/Language.vue";
import localStorageMixin from "@/mixins/localStorage";
import theme from "@/mixins/theme";
import Theme from "@/components/layout/Theme.vue";

export default {
  name: "AuthLayout",
  mixins: [localStorageMixin, theme],
  components: {
    Language,
    Theme,
  },
  mounted() {
    try {
      this.onChangeSelectedTheme(this.getStorageItem("theme"));
    } catch (e) {
      /* continue regardless of error */
    }
  },
  computed: {
    bgColor() {
      return this.$vuetify.theme.defaults.light.gray;
    },
  },
};
</script>

<style lang="scss">
@import "authLayout";
</style>
